import { default as _91id_93WQbWGudub6Meta } from "/opt/buildhome/repo/pages/home/classes/[id].vue?macro=true";
import { default as createTLZld3z48fMeta } from "/opt/buildhome/repo/pages/home/classes/create.vue?macro=true";
import { default as indexTmv1g9ddE4Meta } from "/opt/buildhome/repo/pages/home/classes/index.vue?macro=true";
import { default as indexX8FFvhvP8SMeta } from "/opt/buildhome/repo/pages/home/dashboard/index.vue?macro=true";
import { default as _91id_93aKwfR2S8B9Meta } from "/opt/buildhome/repo/pages/home/discipline/[id].vue?macro=true";
import { default as createazcHO714GlMeta } from "/opt/buildhome/repo/pages/home/discipline/create.vue?macro=true";
import { default as index3qlNtruTIGMeta } from "/opt/buildhome/repo/pages/home/discipline/index.vue?macro=true";
import { default as _91id_93Qdu7eIQ3HmMeta } from "/opt/buildhome/repo/pages/home/knowledgeArea/[id].vue?macro=true";
import { default as createh4yq5wZbKHMeta } from "/opt/buildhome/repo/pages/home/knowledgeArea/create.vue?macro=true";
import { default as indexViAfTV2tdRMeta } from "/opt/buildhome/repo/pages/home/knowledgeArea/index.vue?macro=true";
import { default as _91id_93NeqAfQGyQ7Meta } from "/opt/buildhome/repo/pages/home/school/[id].vue?macro=true";
import { default as createYyasHdtpc1Meta } from "/opt/buildhome/repo/pages/home/school/create.vue?macro=true";
import { default as indexmvfq7KLm3CMeta } from "/opt/buildhome/repo/pages/home/school/index.vue?macro=true";
import { default as _91id_931VEprNbgnnMeta } from "/opt/buildhome/repo/pages/home/segment/[id].vue?macro=true";
import { default as createTOmxJUMsCvMeta } from "/opt/buildhome/repo/pages/home/segment/create.vue?macro=true";
import { default as indexguAf1vBtOrMeta } from "/opt/buildhome/repo/pages/home/segment/index.vue?macro=true";
import { default as _91id_939lAuZ7klanMeta } from "/opt/buildhome/repo/pages/home/serie/[id].vue?macro=true";
import { default as createSa01rbauIRMeta } from "/opt/buildhome/repo/pages/home/serie/create.vue?macro=true";
import { default as indexCGjPb0220NMeta } from "/opt/buildhome/repo/pages/home/serie/index.vue?macro=true";
import { default as _91id_93IjacAePCVdMeta } from "/opt/buildhome/repo/pages/home/settings/user/[id].vue?macro=true";
import { default as createVtDDr2pgipMeta } from "/opt/buildhome/repo/pages/home/settings/user/create.vue?macro=true";
import { default as editipwb6Nup0YMeta } from "/opt/buildhome/repo/pages/home/settings/user/edit.vue?macro=true";
import { default as indexmeEog3eJR8Meta } from "/opt/buildhome/repo/pages/home/settings/user/index.vue?macro=true";
import { default as home4pMUrBFKiHMeta } from "/opt/buildhome/repo/pages/home.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexVOrFrgjAGIMeta } from "/opt/buildhome/repo/pages/profile/index.vue?macro=true";
import { default as _91token_93ULhUjFcQ6gMeta } from "/opt/buildhome/repo/pages/recovery/[token].vue?macro=true";
import { default as index1Liy9c6jhgMeta } from "/opt/buildhome/repo/pages/recovery/index.vue?macro=true";
export default [
  {
    name: home4pMUrBFKiHMeta?.name ?? "home",
    path: home4pMUrBFKiHMeta?.path ?? "/home",
    meta: home4pMUrBFKiHMeta || {},
    alias: home4pMUrBFKiHMeta?.alias || [],
    redirect: home4pMUrBFKiHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93WQbWGudub6Meta?.name ?? "home-classes-id",
    path: _91id_93WQbWGudub6Meta?.path ?? "classes/:id()",
    meta: _91id_93WQbWGudub6Meta || {},
    alias: _91id_93WQbWGudub6Meta?.alias || [],
    redirect: _91id_93WQbWGudub6Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/classes/[id].vue").then(m => m.default || m)
  },
  {
    name: createTLZld3z48fMeta?.name ?? "home-classes-create",
    path: createTLZld3z48fMeta?.path ?? "classes/create",
    meta: createTLZld3z48fMeta || {},
    alias: createTLZld3z48fMeta?.alias || [],
    redirect: createTLZld3z48fMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/classes/create.vue").then(m => m.default || m)
  },
  {
    name: indexTmv1g9ddE4Meta?.name ?? "home-classes",
    path: indexTmv1g9ddE4Meta?.path ?? "classes",
    meta: indexTmv1g9ddE4Meta || {},
    alias: indexTmv1g9ddE4Meta?.alias || [],
    redirect: indexTmv1g9ddE4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/classes/index.vue").then(m => m.default || m)
  },
  {
    name: indexX8FFvhvP8SMeta?.name ?? "home-dashboard",
    path: indexX8FFvhvP8SMeta?.path ?? "dashboard",
    meta: indexX8FFvhvP8SMeta || {},
    alias: indexX8FFvhvP8SMeta?.alias || [],
    redirect: indexX8FFvhvP8SMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93aKwfR2S8B9Meta?.name ?? "home-discipline-id",
    path: _91id_93aKwfR2S8B9Meta?.path ?? "discipline/:id()",
    meta: _91id_93aKwfR2S8B9Meta || {},
    alias: _91id_93aKwfR2S8B9Meta?.alias || [],
    redirect: _91id_93aKwfR2S8B9Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/discipline/[id].vue").then(m => m.default || m)
  },
  {
    name: createazcHO714GlMeta?.name ?? "home-discipline-create",
    path: createazcHO714GlMeta?.path ?? "discipline/create",
    meta: createazcHO714GlMeta || {},
    alias: createazcHO714GlMeta?.alias || [],
    redirect: createazcHO714GlMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/discipline/create.vue").then(m => m.default || m)
  },
  {
    name: index3qlNtruTIGMeta?.name ?? "home-discipline",
    path: index3qlNtruTIGMeta?.path ?? "discipline",
    meta: index3qlNtruTIGMeta || {},
    alias: index3qlNtruTIGMeta?.alias || [],
    redirect: index3qlNtruTIGMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/discipline/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Qdu7eIQ3HmMeta?.name ?? "home-knowledgeArea-id",
    path: _91id_93Qdu7eIQ3HmMeta?.path ?? "knowledgeArea/:id()",
    meta: _91id_93Qdu7eIQ3HmMeta || {},
    alias: _91id_93Qdu7eIQ3HmMeta?.alias || [],
    redirect: _91id_93Qdu7eIQ3HmMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/knowledgeArea/[id].vue").then(m => m.default || m)
  },
  {
    name: createh4yq5wZbKHMeta?.name ?? "home-knowledgeArea-create",
    path: createh4yq5wZbKHMeta?.path ?? "knowledgeArea/create",
    meta: createh4yq5wZbKHMeta || {},
    alias: createh4yq5wZbKHMeta?.alias || [],
    redirect: createh4yq5wZbKHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/knowledgeArea/create.vue").then(m => m.default || m)
  },
  {
    name: indexViAfTV2tdRMeta?.name ?? "home-knowledgeArea",
    path: indexViAfTV2tdRMeta?.path ?? "knowledgeArea",
    meta: indexViAfTV2tdRMeta || {},
    alias: indexViAfTV2tdRMeta?.alias || [],
    redirect: indexViAfTV2tdRMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/knowledgeArea/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93NeqAfQGyQ7Meta?.name ?? "home-school-id",
    path: _91id_93NeqAfQGyQ7Meta?.path ?? "school/:id()",
    meta: _91id_93NeqAfQGyQ7Meta || {},
    alias: _91id_93NeqAfQGyQ7Meta?.alias || [],
    redirect: _91id_93NeqAfQGyQ7Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/school/[id].vue").then(m => m.default || m)
  },
  {
    name: createYyasHdtpc1Meta?.name ?? "home-school-create",
    path: createYyasHdtpc1Meta?.path ?? "school/create",
    meta: createYyasHdtpc1Meta || {},
    alias: createYyasHdtpc1Meta?.alias || [],
    redirect: createYyasHdtpc1Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/school/create.vue").then(m => m.default || m)
  },
  {
    name: indexmvfq7KLm3CMeta?.name ?? "home-school",
    path: indexmvfq7KLm3CMeta?.path ?? "school",
    meta: indexmvfq7KLm3CMeta || {},
    alias: indexmvfq7KLm3CMeta?.alias || [],
    redirect: indexmvfq7KLm3CMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/school/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931VEprNbgnnMeta?.name ?? "home-segment-id",
    path: _91id_931VEprNbgnnMeta?.path ?? "segment/:id()",
    meta: _91id_931VEprNbgnnMeta || {},
    alias: _91id_931VEprNbgnnMeta?.alias || [],
    redirect: _91id_931VEprNbgnnMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/segment/[id].vue").then(m => m.default || m)
  },
  {
    name: createTOmxJUMsCvMeta?.name ?? "home-segment-create",
    path: createTOmxJUMsCvMeta?.path ?? "segment/create",
    meta: createTOmxJUMsCvMeta || {},
    alias: createTOmxJUMsCvMeta?.alias || [],
    redirect: createTOmxJUMsCvMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/segment/create.vue").then(m => m.default || m)
  },
  {
    name: indexguAf1vBtOrMeta?.name ?? "home-segment",
    path: indexguAf1vBtOrMeta?.path ?? "segment",
    meta: indexguAf1vBtOrMeta || {},
    alias: indexguAf1vBtOrMeta?.alias || [],
    redirect: indexguAf1vBtOrMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/segment/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939lAuZ7klanMeta?.name ?? "home-serie-id",
    path: _91id_939lAuZ7klanMeta?.path ?? "serie/:id()",
    meta: _91id_939lAuZ7klanMeta || {},
    alias: _91id_939lAuZ7klanMeta?.alias || [],
    redirect: _91id_939lAuZ7klanMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/serie/[id].vue").then(m => m.default || m)
  },
  {
    name: createSa01rbauIRMeta?.name ?? "home-serie-create",
    path: createSa01rbauIRMeta?.path ?? "serie/create",
    meta: createSa01rbauIRMeta || {},
    alias: createSa01rbauIRMeta?.alias || [],
    redirect: createSa01rbauIRMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/serie/create.vue").then(m => m.default || m)
  },
  {
    name: indexCGjPb0220NMeta?.name ?? "home-serie",
    path: indexCGjPb0220NMeta?.path ?? "serie",
    meta: indexCGjPb0220NMeta || {},
    alias: indexCGjPb0220NMeta?.alias || [],
    redirect: indexCGjPb0220NMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/serie/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IjacAePCVdMeta?.name ?? "home-settings-user-id",
    path: _91id_93IjacAePCVdMeta?.path ?? "settings/user/:id()",
    meta: _91id_93IjacAePCVdMeta || {},
    alias: _91id_93IjacAePCVdMeta?.alias || [],
    redirect: _91id_93IjacAePCVdMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/user/[id].vue").then(m => m.default || m)
  },
  {
    name: createVtDDr2pgipMeta?.name ?? "home-settings-user-create",
    path: createVtDDr2pgipMeta?.path ?? "settings/user/create",
    meta: createVtDDr2pgipMeta || {},
    alias: createVtDDr2pgipMeta?.alias || [],
    redirect: createVtDDr2pgipMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/user/create.vue").then(m => m.default || m)
  },
  {
    name: editipwb6Nup0YMeta?.name ?? "home-settings-user-edit",
    path: editipwb6Nup0YMeta?.path ?? "settings/user/edit",
    meta: editipwb6Nup0YMeta || {},
    alias: editipwb6Nup0YMeta?.alias || [],
    redirect: editipwb6Nup0YMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/user/edit.vue").then(m => m.default || m)
  },
  {
    name: indexmeEog3eJR8Meta?.name ?? "home-settings-user",
    path: indexmeEog3eJR8Meta?.path ?? "settings/user",
    meta: indexmeEog3eJR8Meta || {},
    alias: indexmeEog3eJR8Meta?.alias || [],
    redirect: indexmeEog3eJR8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/user/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexVOrFrgjAGIMeta?.name ?? "profile",
    path: indexVOrFrgjAGIMeta?.path ?? "/profile",
    meta: indexVOrFrgjAGIMeta || {},
    alias: indexVOrFrgjAGIMeta?.alias || [],
    redirect: indexVOrFrgjAGIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93ULhUjFcQ6gMeta?.name ?? "recovery-token",
    path: _91token_93ULhUjFcQ6gMeta?.path ?? "/recovery/:token()",
    meta: _91token_93ULhUjFcQ6gMeta || {},
    alias: _91token_93ULhUjFcQ6gMeta?.alias || [],
    redirect: _91token_93ULhUjFcQ6gMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/recovery/[token].vue").then(m => m.default || m)
  },
  {
    name: index1Liy9c6jhgMeta?.name ?? "recovery",
    path: index1Liy9c6jhgMeta?.path ?? "/recovery",
    meta: index1Liy9c6jhgMeta || {},
    alias: index1Liy9c6jhgMeta?.alias || [],
    redirect: index1Liy9c6jhgMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/recovery/index.vue").then(m => m.default || m)
  }
]