import { getDatabase, ref, onValue, set, get, child, DataSnapshot, update, } from "firebase/database";

export default class Realtime {
  db = getDatabase();

  set<T extends object> (path: string, data: T) {
    return set(ref(this.db, path), data);
  }

  update<T extends object> (path: string, data: T) {
    return update(ref(this.db, path), data);
  }

  get (path: string) {
    const dbRef = ref(getDatabase());

    return get(child(dbRef, path));

    // return get(child(dbRef, channel)).then((snapshot) => {
    //   if (snapshot.exists()) {
    //     console.log(snapshot.val());
    //   } else {
    //     console.log("No data available");
    //   }
    // }).catch((error) => {
    //   console.error(error);
    // });
  }

  listening (path: string, onChange: (snapshot: DataSnapshot) => void) {
    return onValue(ref(this.db, path), onChange);
  }

}
