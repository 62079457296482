<template>
  <Modal is-open :on-close="closeModal">
    <template #title>
      <p class="text-[18px] font-normal">
        Por motivos de segurança, você foi desconectado automaticamente. Para continuar, por favor, faça login novamente.
      </p>
    </template>
    <template #body>
      <form @submit.prevent="onLogin">
        <div class="mb-5 mt-7">
          <Input
            name="text"
            type="text"
            placeholder="E-mail"
            validate-with-on-blur
            :value="formData.email"
            :on-change="(value: string) => formData.email = value"
            :on-validate="(isError: boolean) => formDataError.email = isError"
            :validate="(value: string) => useValidations('email', value)"
            :helper-text="'e-mail inválido'"
            :validate-on-update="isValideAll"
          />
        </div>

        <div class="mb-8">
          <Input
            name="password"
            type="password"
            placeholder="Senha"
            validate-with-on-blur
            :value="formData.password"
            :on-change="(value: string) => formData.password = value"
            :on-validate="(isError: boolean) => formDataError.password = isError"
            :validate="(value: string) => value.length >= 4"
            :helper-text="'senha deve ser maior que 4 caracteres'"
            :validate-on-update="isValideAll"
          />
        </div>
        <p v-if="errorText && !isLoading" class="text-red mb-5">
          {{ errorText }}
        </p>
        <div class="flex justify-center">
          <Button
            :is-loading="isLoading"
            loading-size="md"
            is-width-full
            type="submit"
          >
            {{ "Login" }}
          </Button>
        </div>
      </form>
    </template>
    <template #footer />
  </Modal>
</template>

<script setup lang="ts">

const context = useNuxtApp();

const isLoading = ref(false);
const isError = ref(true);
const isValideAll = ref(false);
const errorText = ref("");

const formData = reactive({
  email: "",
  password: ""
});

const formDataError = reactive({
  email: true,
  password: true
});

const onLogin = (e: Event) => {
  e.preventDefault();
  isError.value = false;

  isValideAll.value = true;

  if (!validateFormData(formDataError)) { return; }

  isLoading.value = true;

  context.$onLogin(formData.email, formData.password)
    .then(async () => {
      if (context.$user.value) {
        const profile = await context.$getProfile();

        if (profile) {
          await context.$setProfileToken(profile)
            .then(() => {
              closeModal();
              formData.email = "";
              formData.password = "";
            });
        }
      }
    })
    .catch((error) => {
      isLoading.value = false;
      if (error.message === "auth/invalid-credential") {
        errorText.value = "E-mail ou senha inválido";
      } else {
        context.$logout();
        useRouter().replace("/");
      }
    }).finally(() => {
      isLoading.value = false;
    });
};

function closeModal () {
  context.$showModalAuth.value = false;
}
</script>
