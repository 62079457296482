<template>
  <div aria-live="assertive" class="z-999999 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-17">
    <div class=" flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-if="props.show" class="dark:bg-meta-4 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex flex-grow items-center gap-5">
              <div
                class="flex h-10 w-full max-w-10 items-center justify-center rounded-full bg-blue-600"
              >
                <slot name="icon" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="mb-0.5 text-title-xsm font-medium text-black dark:text-white">
                  <slot name="title" />
                </p>

                <p class="text-sm font-medium">
                  <slot name="text" />
                </p>

                <div class="mt-3 flex space-x-7">
                  <slot name="footer" />
                </div>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button" class="inline-flex rounded-md dark:bg-meta-4 bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="$emit('close')">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { XMarkIcon, } from "@heroicons/vue/20/solid";

const props = defineProps<{
  show?: boolean
}>();

defineEmits(["close"]);

</script>
