import { useValidationsBR, } from "validations-br";

export const validateFormData = (formDataValidate: Record<string, boolean>) => !Object.keys(formDataValidate).some(key => formDataValidate[key]);

export const resetValidationFormData = (formDataValidate: Record<string, boolean>) => Object.keys(formDataValidate).forEach(key => formDataValidate[key] = true);

export const allowOnlyNumbers = (value: string) => value = value.replace(/\D/g, "");

export const strMin = (value: string, min: number) => value.length >= min;

export const strMax = (value: string, max: number) => value.length > max ? value.substring(0, max) : value;

export const isWebUrl = (value: string) => /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/.test(value);

export const useValidations = (type: ValidationsBrType, value: string) => useValidationsBR(type, value);

export const isValidPassword = (value: string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&].{8,}$/.test(value);

export const isInteger = (value: string) => value.replace(/\D/g, "");

export const isValidInepCode = (value: string) => /^\d{8}$/.test(value);

export const validateToken = (token?: string | null) => {
  if (!token) {
    return false;
  }
  try {
    const decodedPayload = JSON.parse(base64Decode(token.split(".")[1]));

    const today = new Date();
    const expireDate = new Date(decodedPayload.exp * 1000);

    return today < expireDate;
  } catch (error) {
    return undefined;
  }
};
