import Logo from "~/assets/images/logo/LOGO_800X200.png";

const INBOX_DEFAULT_TIMEOUT = 10000;

export default defineNuxtPlugin(() => {
  const context = useNuxtApp();

  const realtime = new Realtime();
  const showNotify = useState<boolean>(() => false);

  const notify = useState<NotifyI>(() => ({
    title: "",
    text: "",
    userId: "",
    userHasSent: ""
  }));

  function onNotify (notifyInfo: NotifyI) {
    showNotify.value = false;
    notify.value = notifyInfo;
    showNotify.value = true;

    setTimeout(() => {
      showNotify.value = false;
    }, INBOX_DEFAULT_TIMEOUT);

    browserNotify(notifyInfo);
  }

  function browserNotify (notifyInfo: NotifyI) {
    const onNotify = () => {
      const notification = new Notification(notifyInfo.title, {
        body: notifyInfo.text,
        icon: Logo,
        requireInteraction: true,
        data: { action: "Acessar" }
      });

      notification.onclick = function () {
        notifyInfo?.redirects?.[0]?.link && window.open(notifyInfo.redirects[0].link, "_blank");
        // notify?.redirects?.[0]?.link && useRouter().push(notify?.redirects?.[0].path);
      };
    };

    if (!("Notification" in window)) {
      // eslint-disable-next-line no-console
      console.error("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      onNotify();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          onNotify();
        }
      });
    }
  }

  function startNotifyListening () {
    let fistCall = true;

    if (context?.$user?.value?.uid) {
      realtime.listening("/notify/" + context.$user.value.uid, (snap) => {
        if (fistCall) {
          return fistCall = false;
        }

        if (snap.val()) {
          onNotify(snap.val());
        }
      }
      );
    }
  }

  function setShowNotify (value: boolean) {
    showNotify.value = value;
  }

  return {
    provide: {
      showNotify,
      notify,
      onNotify,
      setShowNotify,
      startNotifyListening,
    }
  };
});
