<template>
  <div aria-live="assertive" class="z-999999 mt-17 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          :class="`
          pointer-events-auto
          w-full
          max-w-sm
          overflow-hidden
          border-l-6
          rounded-lg
          p-4
          shadow-md
          ${getColorByType(type).colorTwoBg}
          ${getColorByType(type).colorOneBorder}
          `"
        >
          <div class="flex flex-grow items-center gap-5">
            <div :class="`${getColorByType(type).colorOneBg} mr-2 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg `">
              <SuccessIcon v-if="type === 'success'" class="fill-white h-[12px] w-[16px]" />
              <DangerIcon v-else-if="type === 'danger'" class="fill-white h-[13px] w-[13px]" />
              <WarningIcon v-else class="fill-white h-[16px] w-[19px]" />
            </div>

            <div class="w-0 flex-1">
              <p :class="`mb-3 text-[0.9rem] font-bold ${getColorByType(type).colorOneText}`">
                {{ props.title }}
              </p>

              <p class="text-[0.8rem]  leading-relaxed text-black">
                {{ props.text }}
              </p>
            </div>

            <div class=" flex flex-shrink-0">
              <button type="button" class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2" @click="emit('close')">
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-5 w-5 fill-boxdark-2" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { XMarkIcon, } from "@heroicons/vue/20/solid";

const props = defineProps<{
  show?: boolean,
  title: string,
  text: string,
  type: "success" | "warning" | "danger";
}>();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emit = defineEmits<{(e: "close"): void}>();

function getColorByType (type: string) {
  switch (type) {
  case "success":
    return {
      colorOneBg: "bg-success",
      colorTwoBg: "bg-success-2",
      colorOneText: "text-success",
      colorOneBorder: "border-success",
    };
  case "warning":
    return {
      colorOneBg: "bg-warning",
      colorTwoBg: "bg-warning-2",
      colorOneText: "text-warning",
      colorOneBorder: "border-warning",
    };
  case "danger":
    return {
      colorOneBg: "bg-danger",
      colorTwoBg: "bg-danger-2",
      colorOneText: "text-danger",
      colorOneBorder: "border-danger",
    };
  default:
    return {
      colorOneBg: "",
      colorTwoBg: "",
      colorOneText: "",
      colorOneBorder: "",
    };
  }
}

</script>
