import { type UserCredential, browserLocalPersistence, signInWithEmailAndPassword, } from "firebase/auth";

export default defineNuxtPlugin(() => {
  const { auth } = useFirebase();
  const errorCode = useState(() => "");
  const user = useState<UserCredential["user"] | undefined>(() => undefined);
  const showModalAuth = useState<boolean>(() => false);

  async function fetchLogin () {
    await auth.setPersistence(browserLocalPersistence);

    if (auth.currentUser) {
      user.value = auth.currentUser;
    }
  };

  async function onLogin (email: string, password: string) {
    await auth.setPersistence(browserLocalPersistence);

    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        user.value = userCredential.user;
      })
      .catch((error) => {
        errorCode.value = (String(error.code));
        throw new Error(errorCode.value);
      });
  };

  async function getProfiles () {
    if (user.value) {
      const idToken = await user.value.getIdToken();

      return useAuth().auth("https://ci.sjb.rj.gov.br", idToken)
        .then((response) => {
          if (Array.isArray(response.data.list_of_users)) {
            return response.data.list_of_users;
          }

          throw new Error("Profile not found");
        });
    }
  }
  async function getProfile () {
    try {
      const userProfile = JSON.parse(localStorage.getItem("userProfile") || "") as UserProfileI;

      if (validateToken(userProfile.auth_code)) {
        return userProfile;
      }

      const profiles = await getProfiles();

      if (profiles) {
        return profiles.find(profile => profile.group_name.S === userProfile.group_name.S);
      }
    } catch {
      return undefined;
    }
  }

  function setProfileToken (userProfile: UserProfileI) {
    return useAuth().validate(userProfile.auth_code)
      .then((response) => {
        localStorage.setItem("userProfile", JSON.stringify(userProfile));
        localStorage.setItem("token", response.data.token);
      });
  }

  function logout () {
    user.value = undefined;

    return auth.signOut();
  }

  return {
    provide: {
      fetchLogin,
      onLogin,
      logout,
      user,
      errorCode,
      validateToken,
      getProfiles,
      getProfile,
      setProfileToken,
      showModalAuth
    }
  };
});
