import type { AxiosError, } from "axios";

const INBOX_DEFAULT_TIMEOUT = 4000;

export default defineNuxtPlugin(() => {
  const showAlert = useState<boolean>(() => false);
  const context = useNuxtApp();

  const alert = useState<AlertI>(() => ({
    title: "",
    text: "",
    type: "" as "success" | "warning" | "danger",
  }));

  function onAlert (alertInfo: AlertI) {
    showAlert.value = false;
    alert.value = alertInfo;
    showAlert.value = true;

    setTimeout(() => {
      showAlert.value = false;
    }, INBOX_DEFAULT_TIMEOUT);
  }

  function setShowAlert (value: boolean) {
    showAlert.value = value;
  }

  function notifyRequestError (error: AxiosError) {
    if (error.response?.status === 401) {
      context.$showModalAuth.value = true;
    } else if (error.response?.status) {
      context.$onAlert({
        title: `Ocorreu um error! Status: ${error.response.status}`,
        text: "Não foi possível realizar a operação",
        type: "danger",
      });
    } else {
      context.$onAlert({
        title: "Ocorreu um error!",
        text: "Não foi possível completar a operação, verifique sua conexão e tente novamente",
        type: "danger",
      });
    }
  }

  return {
    provide: {
      showAlert,
      alert,
      onAlert,
      setShowAlert,
      notifyRequestError,
    }
  };
});
